<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <form action="" method="post" class="form-login" @submit="submit">
        <ion-grid>
          <ion-row>
            <ion-col>
              <img src="images\logo_hbj_tondo.png">
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <!--ion-input v-model="email" type="email" clear-input placeholder="Email"></ion-input-->
              <ion-input v-model="name" type="text" clear-input placeholder="Username"></ion-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-input v-model="password" type="password" clear-input placeholder="Password"></ion-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button type="submit" expand="block">Login</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
  import {
    IonButton,
    IonContent,
    IonPage,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    loadingController,
    toastController
  } from '@ionic/vue';

  export default {
    name: 'Login',
    components: {
      IonContent,
      IonPage,
      IonButton,
      IonInput,
      IonGrid,
      IonRow,
      IonCol
    },
    data: function(){
      return {
        email: '',
        password: '',
        name:'',
        user:{
          name:'',
          email:'',
          password:'',
          role:0,
          company:'',
        },
        idoneityToday:'',
      }
    },
    methods: {
      submit: async function(event) {
        event.preventDefault();
        const loading = await loadingController
        .create({
          duration: '10000',
        });
        await loading.present();
        //this.axios.post(this.apiUrl + 'login', { email: this.email, password: this.password })
        this.axios.post(this.apiUrl + 'login', { name: this.name, password: this.password })
        .then(async (response) => {
          loading.dismiss()
          let data = response.data
          if(data.status_code != 200) {
            const toast = await toastController
            .create({
              color: 'danger',
              message: data.message,
              duration: 2000
            })
            return toast.present();
          }else{
            this.$store.commit('setApiToken', data.api_token)
            this.$store.commit('setUserId', data.id)
            this.$store.commit('setUserName', data.name)
            this.$store.commit('setUserRole', data.role)
            const toast = await toastController
            .create({
              color: 'success',
              message: 'Login effettuato con successo',
              duration: 2000
            })
            await toast.present();
            this.axios.post(this.apiUrl + 'save-log', {name: this.name})
            this.$router.push('/folder/idoneity');
          }
        })
      },
      requestResetPassword() {
        this.$http.post("/auth/reset-password", {email: this.email}).then(result => {
          this.response = result.data;
        }, error => {
          console.error(error);
        });
      },
    } 
  }

</script>

<style scoped>
  .form-login {
    max-width: 500px;
    margin: 30px auto 0;
  }
  img{
    width:60%;
    height:90%;
    display:block;
    margin-left:auto;
    margin-right:auto;
  }
  ion-input{
    border:2px solid white;
    border-radius:5px;
  }
  ::-webkit-input-placeholder {
    color:white;
  }
  ion-content{
    --background: url(https://www.hbj-group.com/wp-content/uploads/2020/07/Consulenza-qualit%C3%A0-e-certificazioni-ABJ-SISTEMI-1.jpg) 0 0/100% 100% no-repeat;
  }
</style>